'use client'
import React, { forwardRef, useEffect, useRef } from 'react'
import { Input } from '../ui/input'
import { FaMagnifyingGlass } from 'react-icons/fa6'
import { useRouter, useSearchParams } from 'next/navigation'
import { X } from 'lucide-react'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { cn } from '@/lib/utils'
export default function SearchBar() {
    const [open, setOpen] = React.useState(false)
    const closeDropdown = () => setOpen(false)
    const ref = useRef<HTMLInputElement>(null)

    const handleTriggerClick = () => {
        ref.current?.focus()
    }

    return (
        <>
            <DropdownMenu open={open} onOpenChange={setOpen}>
                <DropdownMenuTrigger asChild className="md-xl:hidden">
                    <button className='text-black transition-all rounded-full h-fit' >
                        <FaMagnifyingGlass className='m-2 size-5' />
                    </button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-dvw z-[152] md-xl:hidden" align="end" forceMount onAnimationEnd={handleTriggerClick}>
                    <div className='m-2'>
                        <SearchBarMain closeDropdown={closeDropdown} className='md:px-20 md-xl:px-0' key={1} ref={ref} />
                    </div>
                </DropdownMenuContent>
            </DropdownMenu>
            <SearchBarMain className='hidden md-xl:flex' key={2} />
        </>
    )
}

type Props = {
    closeDropdown?: () => void
    className?: string
}

const SearchBarMain = forwardRef((props: Props, ref: React.ForwardedRef<HTMLInputElement>) => {
    const sp = useSearchParams()
    const router = useRouter()
    const [value, setValue] = React.useState(sp.get('search') || '')
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        search(value)
        props.closeDropdown && props.closeDropdown()
        e.currentTarget.querySelector('input')?.blur()
    }
    const search = (s: string | undefined | null) => {
        const currentSPString = new URLSearchParams(sp)
        if (!s) currentSPString.delete('search')
        else currentSPString.set('search', s)

        router.push('?' + currentSPString.toString())
    }

    const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value)
    }

    const resetSearch = () => {
        search(null)
        setValue('')
        props.closeDropdown && props.closeDropdown()
    }

    return (
        <form className={cn('flex items-center w-full h-full ', props.className)} onSubmit={handleSubmit}>
            <div className='relative flex w-full'>
                <Input
                    name='search'
                    className='py-1 rounded-full me-2 '
                    placeholder='Search'
                    defaultValue={sp.get('search') || ''}
                    value={value}
                    onChange={handleValueChange}
                    ref={ref}
                />
                {sp.get('search') && <X className='absolute text-gray-600 transform -translate-y-1/2 cursor-pointer right-5 top-1/2 size-4' onClick={resetSearch} />}
            </div>
            <button className='text-white transition-all bg-black rounded-full h-fit hover:bg-slate-800'>
                <FaMagnifyingGlass className='m-2 size-4.5' />
            </button>
        </form>
    )
}
)

SearchBarMain.displayName = 'SearchBarMain'
