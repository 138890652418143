'use client'

import { Button } from "@/components/ui/button"
import { useCommonContext } from '@/contexts/CommonContext'
import { LogIn, LogOut } from 'lucide-react'
import { signOut } from 'next-auth/react'
import { DropdownMenuItem } from "../ui/dropdown-menu"

export default function LoginButton() {
    const [commonContext, setCommonContext] = useCommonContext()

    const handleOpenModal = () => {
        setCommonContext({ ...commonContext, loginModalOpen: true, loginModalAction: 'login' })
    }
    return (
        <Button
            variant='link'
            className='flex gap-1 items-center px-0 py-0 text-ffmain-900'
            onClick={handleOpenModal}
            id='login-button'
        >
            <LogIn className='' />
            <p className='hidden sm:block'>
                Log In
            </p>
        </Button>
    )

}

export const LogoutDropdownButton = () => {
    return (
        <DropdownMenuItem onClick={() => signOut()} id='logout-button'>
            <LogOut className="size-5 me-2" />
            Log out
        </DropdownMenuItem>
    )
}