'use client'

import { useCart } from '@/hooks/useCart'
import { cn } from '@/lib/utils'
import { ShoppingCartIcon } from 'lucide-react'

export default function CartButton() {
    const cart = useCart()
    const count = cart.cartItems.map(x => x.quantity).reduce((acc, x) => acc + x, 0)
    return (
        <button onClick={cart.openCart} className='flex gap-1 px-0 py-0 group font-semibold text-sm items-center justify-center'>
            <div className='relative'>
                <ShoppingCartIcon
                    className='size-7 stroke-current text-slate-900 fill-transparent group-hover:fill-ffmain-700 group-hover:text-ffmain-700 transition-all duration-300'
                />
                <p className={cn(
                    'absolute top-0 left-0 hidden font-mono bg-red-700 py-0.5 px-1.5 text-xs text-white rounded-full leading-tight transform -translate-x-1/2 -translate-y-1/2',
                    count > 0 && 'flex items-center justify-center'
                )}>
                    {count}
                </p>
            </div>
            <p className='hidden md:block group-hover:text-ffmain-700 hover:underline transition-all duration-300'>Cart</p>
        </button>

    )
}
