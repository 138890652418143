'use client'
import { useCommonContext } from '@/contexts/CommonContext'
import { LogIn, LogOut } from 'lucide-react'
import { signOut } from 'next-auth/react'
import React from 'react'

export default function DrawerLogoutButton() {
    return (
        <button className="py-2 px-2 transition-all rounded-md flex hover:bg-ffmain-100 text-slate-500 hover:text-ffmain-600" onClick={() => signOut()}>
            <LogOut className="me-2" /> Logout
        </button>
    )
}

export function DrawerLoginButton() {
    const [_, setCommonCtx] = useCommonContext()
    const handleLogin = () => {
        setCommonCtx(prev => ({ ...prev, loginModalOpen: true, loginModalAction: 'login' }))
    }
    return (
        <button className="py-2 px-2 transition-all rounded-md flex hover:bg-ffmain-100 text-ffmain-800 hover:text-ffmain-900" onClick={handleLogin}>
            <LogIn className="me-2" /> Login
        </button>
    )
}
